"use client";

import Head from 'next/head';
import { usePathname } from 'next/navigation';
import { clientLogError } from '../helpers/clientLoggingHelper';
import { iErrorHandlerRequestModel } from '../models/apiModels/requestModels/errorHandlerRequestModel';
import { iErrorHandlerResponseModel } from '../models/apiModels/errorHandlerResponseModel';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import { iSimplifiedRequestContext } from '../models/contextModels/requestContext';
import ClientFooter from '../components/Footer/ClientFooter';
import ClientHeader from '../components/Header/clientHeader';

interface ErrorProps {
  error: Error;
  reset?: () => void;
}

export default function ErrorPage({ error }: ErrorProps) {
  try {
    const pathname = usePathname();
    const [simplifiedRequestContext, setSimplifiedRequestContext] = useState<iSimplifiedRequestContext | undefined>(undefined);

    useEffect(() => {
      const fetchSimplifiedRequestContext = async () => {
        try {
          const response = await fetch(`/api/content/tryGetSimplifiedRequestContext?path=${pathname}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });

          if (!response.ok) {
            const responseText = await response.text();
            clientLogError("error.tsx", "fetchSimplifiedRequestContext", `Network response was not ok: ${responseText}`);
          }

          const simplifiedRequestContext = await response.json() as iSimplifiedRequestContext;
          setSimplifiedRequestContext(simplifiedRequestContext);
        }
        catch (error) {
          clientLogError("error.tsx", "fetchSimplifiedRequestContext", error);
        }
        finally {
          setIsLoadingContextFetching(false);
        }
      };

      fetchSimplifiedRequestContext();
    }, [pathname]);

    const [hasHandledError, setHasHandledError] = useState(false);
    const [translations, setTranslations] = useState<any | undefined>(undefined);
    const [isLoadingErrorHandling, setIsLoadingErrorHandling] = useState(true);
    const [isLoadingContextFetching, setIsLoadingContextFetching] = useState(true);

    useEffect(() => {
      if (!hasHandledError) {
        const acceptLanguageHeader = navigator.language || "en";

        const handleError = async () => {
          try {
            const requestModel: iErrorHandlerRequestModel = {
              acceptLanguageHeader: acceptLanguageHeader,
              error: error,
              path: pathname
            };
            const baseUrl = process.env.NEXT_PUBLIC_FRONTEND_URL;
            const url = `${baseUrl}/api/errorHandler`;
            const response = await fetch(url, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(requestModel),
            });

            if (!response.ok) {
              clientLogError(`Failed to send error at ${pathname}`, error);
            }

            const data = await response.json();
            const responseModel: iErrorHandlerResponseModel = data.responseModel;

            if (responseModel) {
              setTranslations(responseModel.translations);
            }
          } catch (error) {
            clientLogError(`Failed to handle error at ${pathname}`, error);
          } finally {
            setHasHandledError(true);
            setIsLoadingErrorHandling(false);
          }
        };

        handleError();
      }
    }, [hasHandledError, pathname, error]);


    return (
      <>
        <Loader loading={isLoadingContextFetching && isLoadingContextFetching} />
        {!isLoadingErrorHandling && !isLoadingContextFetching && (
          <>
            {simplifiedRequestContext ? (
              <>
                <script
                  dangerouslySetInnerHTML={{
                    __html: `
              document.addEventListener('DOMContentLoaded', function () {
                  cookieconsent.run({
                      "notice_banner_type": "standalone",
                      "consent_type": "express",
                      "palette": "light",
                      "language": "${simplifiedRequestContext.language}",
                      "page_load_consent_levels": ["strictly-necessary"],
                      "notice_banner_reject_button_hide": false,
                      "preferences_center_close_button_hide": false,
                      "page_refresh_confirmation_buttons": false,
                      "website_name": "Green Storage",
                      "website_privacy_policy_url": "${simplifiedRequestContext.mainUrl}${simplifiedRequestContext.configurations.orderingFlow.integrityPolicyPage.url}",
                      "callbacks": {
                          "scripts_specific_loaded": (level) => {
                              switch (level) {
                                  case 'targeting':
                                      gtag('consent', 'update', {
                                          'ad_storage': 'granted',
                                          'ad_user_data': 'granted',
                                          'ad_personalization': 'granted',
                                          'analytics_storage': 'granted'
                                      });
                                      break;
                              }
                          }
                      },
                      "callbacks_force": true
                  });
              });
          `,
                  }}
                />
                <noscript>
                  ePrivacy and GPDR Cookie Consent management by <a href="https://www.TermsFeed.com/" rel="nofollow">TermsFeed Privacy Generator</a>
                </noscript>

                <ClientHeader
                  isLoggedIn={false}
                  country={simplifiedRequestContext.country}
                  menu={simplifiedRequestContext.menu}
                  loginText={simplifiedRequestContext.translations.general.login}
                  myPagesText={simplifiedRequestContext.translations.general.myPages}
                />

                <main className="container mx-auto pb-10 lg:pb-20 pt-10 flex items-center justify-center min-h-screen">
                  <div className="w-full max-w-xs sm:max-w-md text-center">
                    <Image src="/assets/img/broken_heart.png" alt="500" width={300} height={300} className="mx-auto mb-10" />
                    <h1 className="text-center">{translations.general.errors.e500.title}</h1>
                    <p className="text-center">{translations.general.errors.e500.message}</p>
                    <Link href={`/${simplifiedRequestContext.country}`} className="text-center block mt-4 btn">{translations.general.errors.backToStart}</Link>
                  </div>
                </main>

                <ClientFooter
                  areas={simplifiedRequestContext.configurations.areas}
                  country={simplifiedRequestContext.country}
                  language={simplifiedRequestContext.language}
                  translations={simplifiedRequestContext.translations}
                />
              </>
            ) : (
              <>
                <Head>
                  <title>{translations?.general?.errors?.e500?.title || "Error"}</title>
                </Head>

                <main className="container mx-auto pb-10 lg:pb-20 pt-10 flex items-center justify-center min-h-screen">

                  <div className="w-full max-w-xs sm:max-w-md text-center">
                    <Image src="/assets/img/broken_heart.png" alt="500" width={300} height={300} className="mx-auto mb-10" />
                    <h1 className="text-center">{translations?.general?.errors?.e500?.title || "Error"}</h1>
                    <p className="text-center">{translations?.general?.errors?.e500?.message || "Something went wrong"}</p>
                    <Link href={`/`} className="text-center block mt-4 btn">{translations?.general?.errors?.backToStart || "Back to start"}</Link>
                  </div>
                </main>
              </>
            )}

          </>
        )}
      </>
    );
  } catch (error) {
    return <>
      <Head>
        <title>{"Error"}</title>
      </Head>

      <main className="container mx-auto pb-10 lg:pb-20 pt-10 flex items-center justify-center min-h-screen empty-error-container">

        <div className="w-full max-w-xs sm:max-w-md text-center">
          <Image src="/assets/img/broken_heart.png" alt="500" width={300} height={300} className="mx-auto mb-10" />
          <h1 className="text-center">{"Error"}</h1>
          <p className="text-center">{"Something went wrong"}</p>
          <Link href={`/`} className="text-center block mt-4 btn">{"Back to start"}</Link>
        </div>
      </main>
    </>
  }
}