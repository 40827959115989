import { iRequestContext } from '@/app/models/contextModels/requestContext';
import Image from 'next/image';
import styles from './sociallinks.module.scss';

interface Props {
    twitterUrl?: string;
    linkedInUrl?: string;
    instagramUrl?: string;
    facebookUrl?: string;
    translations: any;
}


export default function SocialLinks({ twitterUrl, linkedInUrl, instagramUrl, facebookUrl, translations }: Props) {
    return (
        <>
            {twitterUrl && (
                <a 
                href={twitterUrl} 
                className={`btn ${styles.link} flex justify-center items-center`} 
                target="_blank" 
                rel="noreferrer nofollow noopener"
                title={`${translations.general.goTo} ${translations.general.our} ${translations.general.twitter}`}>
                    <Image
                        src="/assets/img/icons/icon_twitter_white.svg"
                        width={16}
                        height={16}
                        alt={`${translations.general.twitter} ${translations.general.icon}`}
                        className={styles.icon}
                    />
                </a>
            )}
            {linkedInUrl && (
                <a 
                href={linkedInUrl} 
                className={`btn ${styles.link} flex justify-center items-center`} 
                target="_blank" rel="noreferrer nofollow noopener"
                title={`${translations.general.goTo} ${translations.general.our} ${translations.general.linkedIn}`}>
                    <Image
                        src="/assets/img/icons/icon_linkedIn_white.svg"
                        width={16}
                        height={16}
                        alt={`${translations.general.linkedIn} ${translations.general.icon}`}
                        className={styles.icon}
                    />
                </a>
            )}
            {instagramUrl && (
                <a 
                href={instagramUrl} 
                className={`btn ${styles.link} flex justify-center items-center`} 
                target="_blank" 
                rel="noreferrer nofollow noopener"
                title={`${translations.general.goTo} ${translations.general.our} ${translations.general.instagram}`}>
                    <Image
                        src="/assets/img/icons/icon_instagram_white.svg"
                        width={16}
                        height={16}
                        alt={`${translations.general.instagram} ${translations.general.icon}`}
                        className={styles.icon}
                    />
                </a>
            )}
            {facebookUrl && (
                <a 
                href={facebookUrl} 
                className={`btn ${styles.link} flex justify-center items-center`} 
                target="_blank" 
                rel="noreferrer nofollow noopener"
                title={`${translations.general.goTo} ${translations.general.our} ${translations.general.facebook}`}>
                    <Image
                        src="/assets/img/icons/icon_facebook_white.svg"
                        width={16}
                        height={16}
                        alt={`${translations.general.facebook} ${translations.general.icon}`}
                        className={styles.icon}
                    />
                </a>
            )}
        </>
    );
}


