'use client'
import Image from "next/image";
import "../../styles/common/header.scss";
import Navbar from "../Navbar/navbar";
import { iMenu } from "@/app/models/iMenu";

interface Props {
  isLoggedIn: boolean;
  country: string;
  menu: iMenu;
  loginText: string;
  myPagesText: string;
}

export default function ClientHeader({ isLoggedIn, country, menu, loginText, myPagesText }: Props) {
  menu.myPagesLoginLink.linkText = loginText;
  menu.myPagesLoggedInLink.linkText = myPagesText;

  return (
    <header className="header fixed w-full z-50 h-20 top-0 bg-gs-green-400 flex items-center">
      <div className="container mx-auto flex items-center px-6 lg:p-0 justify-between">
        <a href={`/${country}`} className="logo">
          <Image
            src="/images/gs_logo_white.svg"
            width={216}
            height={32}
            alt="Green Storage"
            priority
            className="h-auto w-[216px] lg:w-[auto] lg:h-[35px]"
          />
        </a>
        {menu && <Navbar isLoggedIn={isLoggedIn} menu={menu} />}
      </div>
    </header>
  );
}
